// Urdu
export default{
    form: {
        startSurveyButton: "شروع کریں",
        nextButton: "اگلا",
        submitAnswerButton: "جواب جمع کریں",
        continueButton: "جاری رکھیں",
        finishButton: "مکمل",
        headerPercentageCompletion: 'مکمل ہوا',
        headerQuestionsCompleted: 'سوالات',
        headerDefaultErrorMessage: 'اوہ۔ کچھ غلط ہو گیا۔',
        brandingPoweredBy: 'پیشکش',
        brandingPromoWhySurvey: 'سروے کیوں کریں جب آپ <b>{product}</b> استعمال کر سکتے ہیں؟',
        brandingPromoGetStarted: 'آج ہی شروع کریں!',
        submissionPending: "آپ کا وائس فارم جمع کیا جا رہا ہے۔ براہ کرم براؤزر بند نہ کریں۔",
        submissionCanCloseWindow: "آپ کا وائس فارم کامیابی سے جمع ہو گیا ہے۔ آپ اب ونڈو بند کر سکتے ہیں 👍",
        mediaAttachmentOverlayCloseButton: "بند کریں",
        expiredFormHeader: "آنے کا شکریہ!",
        expiredFormMessage: "لگتا ہے کہ یہ سروے ختم ہو چکا ہے۔ براہ کرم وائس فارم بنانے والے سے رابطہ کریں۔",
        notPublishedFormHeader: "وائس فارم شائع نہیں ہوا!",
        notPublishedFormMessage: "لگتا ہے کہ یہ سروے ابھی تک شائع نہیں ہوا ہے۔ براہ کرم وائس فارم بنانے والے سے رابطہ کریں۔",
        notPublishedFormRetryButton: "دوبارہ کوشش کریں",
        errorFormHeader: "اوہ!",
        errorFormMessage: "شاید کچھ غلط ہو گیا ہے۔ براہ کرم بعد میں دوبارہ کوشش کریں۔",
        errorFormRetryButton: "دوبارہ کوشش کریں",
        emptyFormHeader: "آنے کا شکریہ!",
        emptyFormMessage: "اوہ! لگتا ہے کہ یہ فارم اس وقت خالی ہے، کوئی سوال یا فیلڈز نہیں ہیں۔ ہمیں ہونے والی تکلیف کے لیے معذرت ہے۔ اس مسئلے کو حل کرنے کے لیے براہ کرم فارم کے خالق سے رابطہ کریں۔ آپ کے تعاون کا شکریہ!",

        restoreFormHeader: "لگتا ہے آپ کے پاس ایک فارم زیرِ تکمیل ہے!",
        restoreFormMessage: "کیا آپ وہیں سے جاری رکھنا چاہتے ہیں جہاں آپ نے چھوڑا تھا؟",
        restoreFormButton: "فارم جاری رکھیں",
        restoreFormButtonNew: "نیا شروع کریں",

        audioPermissionHeader: "کیا آپ آواز کے ذریعے جواب دینا چاہتے ہیں؟",
        audioPermissionMessage: "بولنا جواب دینے کو تیز اور آسان بنائے گا۔",
        audioPermissionAcceptButton: "ہاں، میں چاہتا ہوں",
        audioPermissionDenyButton: "نہیں، شکریہ",
        audioPermissionErrorHeader: "مائیکروفون دستیاب نہیں ہے",
        audioPermissionErrorMessage: "مائیکروفون تک رسائی کو فعال کرنے کے لیے براہ کرم اپنے براؤزر کی اجازتیں چیک کریں۔",
        audioPermissionErrorButton: "ٹھیک ہے",
        audioRecorderFailedUpload: "ریکارڈنگ اپ لوڈ کرنے میں ناکام",
        silenceDetectorError: "ہم آپ کو نہیں سن سکتے۔ براہ کرم اپنے مائیکروفون کی ترجیحات چیک کریں۔ ٹپ: یہ آپ کا بیرونی مائیک ہو سکتا ہے۔",
        audioRecorderButtonRecord: "ریکارڈ شروع کریں",
        audioRecorderButtonRecordMore: "مزید ریکارڈ کریں",
        voiceResponseSelectionMessage: "آپ کس طرح جواب دینا چاہتے ہیں منتخب کریں...",
        voiceResponseSelectionOrDivider: "یا",
        voiceResponseTextInputPlaceholder: "جواب لکھیں",
        voiceResponseTextInputCharacterCounterMinMessage: "براہ کرم کم از کم {min} حروف درج کریں",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} حروف (کم از کم {min} حروف)",
        voiceResponseTextInputCharacterValidationMessage: "{count} حروف (کم از کم {min} حروف)",

        // checkbox
        checkboxValidationTooFew: "آپ کو کم از کم {min} آپشن منتخب کرنا ہوں گے",
        checkboxValidationTooMany: "آپ کو زیادہ سے زیادہ {max} آپشن منتخب کرنے ہوں گے",
        checkboxNoneOfTheAboveOption: "ان میں سے کوئی نہیں",
        checkboxInvalidAnswer: "غلط جواب۔",

        // datepicker
        datePickerPlaceholder: "تاریخ منتخب کریں",

        // dropdown
        // email
        emailLabel: "ای میل",
        emailInvalid: "غلط ای میل۔",
        // file-upload
        fileUploadPluginNameCamera: "کیمرہ",
        fileUploadPluginNameCameraVideo: "ویڈیو ریکارڈ کریں",
        fileUploadPluginScreenCast: "اسکرین کاسٹ",
        fileUploadVideoImportFilesDefault: 'ویڈیو ریکارڈ کریں یا اپ لوڈ کریں:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'ویڈیو ریکارڈنگ آپشن منتخب کریں:',
        fileUploadVideoImportFilesNoCamera: 'اسکرین ویڈیو ریکارڈ کرنے کے لیے بٹن دبائیں',
        fileUploadVideoImportFilesNoScreenCast: 'ویڈیو ریکارڈ کرنے کے لیے بٹن دبائیں',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'ویڈیو ریکارڈ کرنے کے لیے کوئی آپشن دستیاب نہیں',

        // matrix
        matrixValidationMessage: "جواب درست ہے۔",
        matrixRow: "قطار",
        matrixColumn: "کالم",
        matrixRowRequired: "قطار {rowTitle} ضروری ہے۔",
        matrixRadioGroup: "ریڈیو گروپ",
        matrixCheckboxGroup: "چیک باکس گروپ",
        matrixGroupRequired: "قطار {rowTitle} کے لیے {type} {groupTitle} ضروری ہے۔",
        matrixColumnRequired: "قطار {rowTitle} کے لیے کالم {columnTitle} ضروری ہے۔",
        matrixColumnInvalid: "قطار {rowTitle} کے لیے کالم {columnTitle} غلط ہے۔",
        matrixRequired: "ضروری ہے۔",
        matrixNumericMustBeNumber: "یہ ایک نمبر ہونا چاہیے۔",
        matrixNumericMustBeGreaterThenMin: "{min} سے زیادہ ہونا چاہیے۔",
        matrixNumericMustBeLessThenMax: "{max} سے کم ہونا چاہیے۔",
        matrixNumericMustBeInteger: "یہ ایک عدد ہونا چاہیے۔",
        matrixNumericInvalidNumber: "غلط نمبر۔",

        // name
        nameLabel: "نام",
        nameInvalid: "پہلا اور آخری نام درج کریں۔",

        // nps
        npsNotLikely: "بالکل ممکن نہیں",
        npsExtremelyLikely: "بہت زیادہ ممکن",

        // numeric input
        numericInputRequired: "ضروری ہے۔",
        numericInputMustBeNumber: "یہ ایک نمبر ہونا چاہیے۔",
        numericInputMustBeGreaterThenMin: "{min} سے زیادہ ہونا چاہیے۔",
        numericInputMustBeLessThenMax: "{max} سے کم ہونا چاہیے۔",
        numericInputMustBeInteger: "یہ ایک عدد ہونا چاہیے۔",
        numericInputInvalidNumber: "غلط نمبر۔",
        numericInputPlaceholder: "نمبر درج کریں",

        // phone
        phoneInvalid: "غلط فون نمبر۔",
        phoneCountrySelectorLabel: 'ملک کا کوڈ',
        phoneCountrySelectorError: 'ملک منتخب کریں',
        phoneNumberLabel: 'فون نمبر',
        phoneExample: 'مثال:',

        // boolean
        booleanYesLabel: "ہاں",
        booleanNoLabel: "نہیں",

        //questionStep
        questionStepAudioQuestionLabel: "آڈیو سوال",

        // errors
        invalidPhoneNumber: "{phone} غلط فون نمبر ہے۔",
        invalidEmail: "{email} غلط ای میل پتہ ہے۔",
        questionIsRequired: "یہ سوال ضروری ہے۔",
        answerIsNotValid: "جواب درست نہیں ہے۔",
        unfinishedProbingDialogError: "براہ کرم گفتگو مکمل کریں۔",
        cannotResumePartialResponse: "جزوی جواب کو دوبارہ شروع نہیں کیا جا سکتا۔",
        failedToSubmitForm: "فارم جمع کرنے میں ناکام رہا۔ براہ کرم اپنے انٹرنیٹ کنکشن کی جانچ کریں اور دوبارہ کوشش کریں۔",

        //language picker
        searchLanguage: "زبان تلاش کریں",
    }
}
